import Vue from 'vue'
import DOM from '@/plugins/DOM'

const Handler = new Vue()

// Custom Event polyfill for IE 11
if (!window.CustomEvent) {
  window.CustomEvent = (event, params) => {
    params = params || { 
     bubbles: false, cancelable: false, detail: undefined 
    }
    var Event = document.createEvent('CustomEvent')
    Event.initCustomEvent( 
      event, params.bubbles, params.cancelable, params.detail
    )
    return Event
  }
  Window.CustomEvent.prototype = window.Event.prototype
}

/**
 * Custom events, use in Vue component:
 * 
 *   this.$trigger('event-name', params)
 *   events: {
 *     'event-name-to-listen-on': 'name-of-function-to-call'
 *   }
 * 
 * Build-in events:
 * 
 *   window/width
 *   window/height
 *   window/breakpoint
 *   window/scroll
 *   key/esc
 *   key/enter
 *   key/up
 *   key/down
 *   key/left
 *   key/right
 */
const EventsPlugin = class {
  constructor () {
    this.keys = {
      9: 'key/tab',
      13: 'key/enter',
      27: 'key/esc',
      37: 'key/left',
      38: 'key/up',
      39: 'key/right',
      40: 'key/down'
    }
  }
 
  /*
  |--------------------------------------------------------------------------
  | Register
  |--------------------------------------------------------------------------
  */
 
  install (Vue) {

    // set properties
    this.breakpoint = DOM.getBreakpoint()
    this.windowWidth = window.innerWidth
    this.windowHeight = window.innerHeight
    this.windowEvents = true
    this.scrollEvents = true
    this.keyEvents = true
    this.scrollContexts = {}
    this.isTouchDevice = false

    // detect touch
    window.addEventListener('touchstart', () => {
      this.isTouchDevice = true
    }, {
      capture: true,
      once: true
    });

    // register global window events
    this._bindWindowEvents()
    this._bindScrollEvents(window, 'window')
    this._bindKeyEvents()

    Vue.prototype.$trigger = (name, payload) => {
      this.trigger(name, payload)
    }
    Vue.prototype.$events = {
      once: (name, callback) => {
        this.once(name, callback)
      },
      registerScrollContext: (context) => {
        this.registerScrollContext(context)
      },
      unregisterScrollContext: (context) => {
        this.unregisterScrollContext(context)
      },
      toggleWindowEvents: (activate) => {
        this.toggleWindowEvents(activate)
      },
      toggleScrollEvents: (activate) => {
        this.toggleScrollEvents(activate)
      },
      toggleKeyEvents: (activate) => {
        this.toggleKeyEvents(activate)
      },
      isTouch: () => {
        return this.isTouch()
      }
    }

    Vue.mixin({
      beforeCreate: initComponentsEvents,
      destroyed: removeComponentEvents
    })
  }

  /*
  |--------------------------------------------------------------------------
  | Interface
  |--------------------------------------------------------------------------
  */

  /**
   * @param {string} name 
   * @param {object} params 
   */
  trigger (name, payload) {
    Handler.$emit(name, new CustomEvent(
      name, {
        bubbles: true,
        cancelable: true,
        detail: payload,
      }
    ))
  }

  once (name, callback) {
    Handler.$once(name, callback)
  }

  /**
   * @param {mixed} mixed 
   */
  registerScrollContext (mixed) {
    var context = DOM.getElement(mixed)
    if (context instanceof HTMLElement) {
      if (!context.id) {
        return error('registerScrollContext: element needs to have an ID')
      }
      if (!fn.has(this.scrollContexts, context.id)) {
        this._bindScrollEvents(context, context.id)
      }
    }
  }

  /**
   * @param {mixed} mixed 
   */
  unregisterScrollContext (mixed) {
    var context = DOM.getElement(mixed)
    if (context instanceof HTMLElement && context.id) {
      fn.delete(this.scrollContexts, context.id)
    }
  }

  /**
   * @param {boolean} activate 
   */
  toggleWindowEvents (activate) {
    this.windowEvents = activate ? true : false
  }

  /**
   * @param {boolean} activate 
   */
  toggleScrollEvents (activate) {
    this.scrollEvents = activate ? true : false
  }

  /**
   * @param {boolean} activate 
   */
  toggleKeyEvents (activate) {
    this.keyEvents = activate ? true : false
  }

  /**
   */
  isTouch () {
    return this.isTouchDevice
  }

  /*
  |--------------------------------------------------------------------------
  | Event handler window
  |--------------------------------------------------------------------------
  */

  /**
   * Build-in window events
   * can be deactivated by this.$windowEvents(false)
   */
  _bindWindowEvents () {
    window.addEventListener("resize", (Event) => {
      if (!this.windowEvents) {
        return
      }
      let breakpoint = DOM.getBreakpoint()
      if (this.breakpoint !== breakpoint) {
        this.breakpoint = breakpoint
        this.trigger('window/breakpoint', this.breakpoint)
      }
      if (this.windowWidth !== window.innerWidth) {
        this.windowWidth = window.innerWidth
        this.trigger('window/width', this.windowWidth)
      }
      if (this.windowHeight !== window.innerHeight) {
        this.windowHeight = window.innerHeight
        this.trigger('window/height', this.windowHeight)
      }
    }, true)
  }
  _bindScrollEvents (context, name) {

    // init the inter object to hold the scrolling status
    this.scrollContexts[name] = {
      top: context.scrollTop || window.scrollY
    }

    var isScrolling
    context.addEventListener('scroll', (Event) => {
      var id, params
      Event.stopPropagation()
      if (!this.scrollEvents) {
        return
      }
      if (DOM.isElement(Event.target)) {
        id = Event.target.id
        if (this.scrollContexts[id] === undefined) {
          return
        }
        params = DOM.getScrollPos(Event.target, this.scrollContexts[id].top)
      } else {
        id = 'window'
        params = DOM.getScrollPos('window', this.scrollContexts[id].top)
      }
      this.scrollContexts[id].top = params.top
      this.trigger('window/scroll', params)
      window.clearTimeout( isScrolling )
    }, true)
  }

  _bindKeyEvents () {
    document.addEventListener('keydown', (Event) => {
      if (!this.keyEvents) {
        return
      }
      if (this.keys[Event.which]) {
        this.trigger(this.keys[Event.which])
      }
    }, true)
  }
}

/*
|--------------------------------------------------------------------------
| Get events node from component and register the events
|--------------------------------------------------------------------------
*/

function initComponentsEvents () {
  var self = this
  if (this.$options.events) {
    this.$options._hasEvents = true
    fn.each(this.$options.events, (listener, event) => {
      Handler.$on(event, getListener(listener, self))
    });
  }
}

function removeComponentEvents () {
  this.$options._hasEvents = false
}

function getListener(callback, vm) {
  return function () { // not () => {} !!!
    if (vm.$options._hasEvents) {
      return vm[callback].apply(vm, arguments)
    }
  }
}

export default new EventsPlugin()