import API from '@/plugins/API'
import Form from '@/services/Form'

const requestEventStore = {
  namespaced: true,

  /*
  |--------------------------------------------------------------------------
  | State
  |--------------------------------------------------------------------------
  */
  state: {
    result: 0, // 0 = init, 1 = sent, 10 = unknown error, 11 = input error
    data: {
      prename: {
        type: 'text',
        value: null,
        preset: null,
        error: false,
        required: true
      },
      surname: {
        type: 'text',
        value: null,
        preset: null,
        error: false,
        required: true
      },
      // integer, float, bool, date, time, email, select
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Getters
  |--------------------------------------------------------------------------
  */

  getters: {},

  /*
  |--------------------------------------------------------------------------
  | Mutations
  |--------------------------------------------------------------------------
  */

  mutations: {
    initForm (state) {
      state.result = 0
      fn.each(state.data, (field) => {
        if (!field.value) {
          field.value = field.preset
        }
        field.error = false
      })
    },
    noInputError (state) {
      state.result = 0
    },
    formSent (state) {
      state.result = 1
      fn.each(state.data, (field) => {
        field.value = field.preset
        field.error = false
      })
    },
    unknownError (state) {
      state.result = 10
    },
    inputError (state) {
      state.result = 11
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Actions
  |--------------------------------------------------------------------------
  */

  actions: {
    /**
     */
    validate ({ state, getters, commit }) {
      var error = false
      fn.each(state.data, (field, name) => {
        field.error = !Form.typeCheck(field)
        if (field.error) {
          error = true
        }
      })
      if (error) {
        commit('inputError')
        return Promise.reject({ status: state.result })
      } else {
        commit('noInputError')
        return Promise.resolve()
      }
    },

    /**
     */
    submit ({ state, commit, dispatch }) {
      return Promise.resolve()
        .then(() => {
          return dispatch('validate')
        })
        .then(() => {
          var data = {
            __preset__: 'shop',
            __from__: state.data.email.value,
            __date__: fn.dateToString(new Date()),
            __time__: fn.timeToString(new Date())
          }

          // add user data
          fn.each(state.data, (field, name) => {
            data[name] = Form.postValue(field, true)
          })
          return API.submit('email', data)
        })
        .then(() => {
          commit('formSent')
          return Promise.resolve({ status: state.result })
        })
        .catch((reason) => {
          return Promise.reject(reason)
        })
    },

    /**
     * required init
     */
    init: ({ dispatch }) => {
      log('init store contact')
      return Promise.resolve()
    }
  }
}

export default requestEventStore