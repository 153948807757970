import API from '@/plugins/API'
import Locale from '@/services/Locale'
import Image from '@/plugins/Image'

const RootStore = {

  /*
  |--------------------------------------------------------------------------
  | State
  |--------------------------------------------------------------------------
  */

  state: {
    api: null,
    lang: null,
    pagekey: null,
    status: {
      site: null,
      page: null
    },
    languages: {},
    navigation: {},
    site: {},
    page: {},
    images: {},
    image: {}, // due to reactivty
    children: {}
  },

  /*
  |--------------------------------------------------------------------------
  | Getters
  |--------------------------------------------------------------------------
  */

  getters : {
    currentLanguage: (state) => (property) => {
      let language = state.languages[state.lang]
      return property ? language[property] : language
    },
    defaultLanguage: (state) => {
      var lang
      fn.each(state.languages, (language, key) => {
        if (language.default) {
          lang = key
        }
      })
      return lang
    },
    isMultiLanguage: (state) => {
      return Object.keys(state.languages).length > 1
    },
    siteError: (state) => {
      return state.status.site != 200
    },
    pageError: (state) => {
      return state.status.page != 200 || state.status.site != 200
    },
    titleTemplate: (state) => {
      return state.site.meta.titleorder.replace('_', ' | ')
        .replace('site', state.site.meta.title)
        .replace('page', "%s")
    },
    title: (state, getters) => {
      return getters.metaHelper(
        state.page.meta.title,
        state.page.head.title
      )
    },
    titleComplete: (state, getters) => {
      return getters.titleTemplate.replace('%s', getters.title)
    },
    keywords: (state, getters) => {
      return getters.metaHelper(
        state.page.meta.keywords.join(', '),
        state.site.meta.keywords.join(', ')
      )
    },
    description: (state, getters) => {
      return getters.metaHelper(
        fn.htmlToText(state.page.meta.description),
        fn.htmlToText(state.site.meta.description)
      )
    },
    author: (state, getters) => {
      return getters.metaHelper(state.site.meta.author)
    },
    type: (state, getters) => {
      return getters.metaHelper(state.page.meta.type)
    },
    locale: (state) => {
      return fn.upper(state.lang) + '_' + fn.lower(state.lang)
    },
    image: (state, getters) => {
      return getters.metaHelper(state.image.src)
    },
    imageWidth: (state, getters) => {
      return getters.metaHelper(state.image.width)
    },
    imageHeight: (state, getters) => {
      return getters.metaHelper(state.image.height)
    },
    metaHelper: (state) => (var1, var2) => {
      if (fn.isString(var1)) {
        return var1
      }
      if (fn.isString(var2)) {
        return var2
      }
      return ''
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Mutations
  |--------------------------------------------------------------------------
  */

  mutations: {
    setEnviromentVars (state) {
      state.api = process.env.VUE_APP_API
      info(fn.upper(process.env.NODE_ENV) + ' enviroment')
    },
    setLanguage (state, lang) {
      state.lang = lang
    },
    setLanguages (state, response) {
      state.languages = response.data
    },
    setSiteData (state, response) {
      state.status.site = fn.toInteger(response.status)
      state.site = {}
      fn.each(response.data, (values, key) => {
        if (key === 'navigation') {
          state.navigation = values
        } else {
          state.site[key] = values
        }
      })
      state.site = response.data

      // set (meta-)image config
      if (
        fn.has(state.site.api, 'seoImage') &&
        fn.has(state.site.api.metaImage, 'width') &&
        fn.has(state.site.api.metaImage, 'height') && 
        fn.has(state.site.api.metaImage, 'crop')) {
          state.images.config = [
            fn.toInteger(state.site.api.metaImage.width),
            fn.toInteger(state.site.api.metaImage.height),
            state.site.api.metaImage.crop
          ]
      } else {
        state.images.config = [1200, 630, true]
      }

      // set site image
      state.images.site = fn.isObject(state.site.meta.image) ? 
        Image.get(state.site.meta.image, state.images.config, false, true) :
        null
    },
    setPageData (state, response) {
      state.status.page = fn.toInteger(response.status)
      state.pagekey = response.pagekey
      if (fn.has(response, 'data')) {
        state.page = response.data
      } else {
        state.page = {}
      }

      // put children in extra node
      state.children = {}
      if (fn.has(state.page, 'children')) {
        fn.each(state.page.children, (child) => {
          state.children[child.head.id] = child
        })
      }

      // set page image
      state.images.page = fn.isObject(state.page.meta, 'image') ? 
        Image.get(state.page.meta.image, state.images.config, false, true) :
        null

      if (fn.isObject(state.images.page)) {
        state.image = state.images.page
      } else if (fn.isObject(state.images.site)) {
        state.image = state.images.site
      } else {
        state.image = {}
      }
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Actions
  |--------------------------------------------------------------------------
  */

  actions: {

    /**
     * used once on boot
     */
    initSite: ({ dispatch }) => {
      var lang = Locale.detect()
      if (!Locale.isValid(lang)) {
        return Promise.reject({
          msg: 'Fatal error: no valid language detected'
        })
      }
      return dispatch('loadSite', lang)
    },

    /**
     * used once on boot
     * Load information about all available languages and set the fallback
     * language in VueI18n plugin.
     */
    initLanguages: ({ commit }) => {
      return API.languages()
        .then((response) => {
          commit('setLanguages', response)
          fn.each(response.data, (language, lang) => {
            if (language.default) {
              Locale.setFallback(lang)
            }
          })
        })
        .catch((reason) => {
          return Promise.reject(reason)
        })
    },

    /**
     * switch language
     */
    loadLanguage: ({ dispatch, state }, lang) => {
      if (lang === state.lang) {
        return Promise.resolve()
      } else if (!Locale.isValid(lang)) {
        return Promise.reject({
          msg: 'Fatal error: language not valid'
        })
      } else {
        info('switch language to ' + lang)
        return dispatch('loadSite', lang)
      }
    },

    /**
     * Load navigation, site-meta etc. for a specific language
     */
    loadSite: ({ commit }, lang) => {
      return Promise.all([
        API.site(lang),
        API.translations(lang)
      ])
      .then(([ dataSite, dataTranslations ]) => {
        commit('setLanguage', lang)
        commit('setSiteData', dataSite)
        Locale.set(lang, dataTranslations.data)
        info('loaded language and site ' + lang)
      })
      .catch((reason) => {
        return Promise.reject(reason)
      })
    },

    /**
     * init site and dispatch init on all modules
     */
    init: ({ dispatch }, modules) => {
      log('init store root')
      return dispatch('initSite')
        .then(() => {
          var res = []
          fn.each(modules, module => {
            res.push(dispatch(module + '/init'))
          })
          return Promise.all(res)
        })
    }
  }
}

export default RootStore