import Vue from 'vue'
import Vuex from 'vuex'

import rootStore from '@/stores/root'
import cookieStore from '@/stores/cookie'
import cacheStore from '@/stores/cache'
import userStore from '@/stores/user'
import contactStore from '@/stores/contact'

Vue.use(Vuex)

const StoreService = new Vuex.Store(rootStore)

// attention: module name must not exist in root.state
StoreService.registerModule('cookie', cookieStore)
StoreService.registerModule('cache', cacheStore)
StoreService.registerModule('user', userStore)
StoreService.registerModule('contact', contactStore)

export default StoreService