import Vue from 'vue'
import Router from 'vue-router'
import Store from '@/services/Store'
import API from '@/plugins/API'
import Scroll from '@/plugins/Scroll'
import Analytics from '@/plugins/Analytics'
import BaseTemplate from '@/components/templates/Base.vue'

Vue.use(Router)

const templates = {
  'default': () => import(/* webpackChunkName: `home-page` */ '@/components/templates/Default'),
  'home': () => import(/* webpackChunkName: `home-page` */ '@/components/templates/Home'),
}

const pages = {
  'default': () => import(/* webpackChunkName: `default-page` */ '@/components/pages/Default'),
  'home': () => import(/* webpackChunkName: `home-page` */ '@/components/pages/Home'),
  'projects': () => import(/* webpackChunkName: `home-page` */ '@/components/pages/Projects'),
  'error': () => import(/* webpackChunkName: `error-page` */ '@/components/pages/Error')
}

const routerService = class {
  constructor () {
    this.Instance = null
  }

  /*
  |--------------------------------------------------------------------------
  | Router and routes
  |--------------------------------------------------------------------------
  */

  /**
   * Create router
   * @return router
   */
  component () {
    this.Instance = new Router({
      mode: 'history',
      linkActiveClass: '',
      linkExactActiveClass: '',
      routes: [
        {
          path: '/',
          name: 'init',
          component: BaseTemplate,
          redirect: this._getHomeUri(),
          children: [
            {
              // just to make incomplete routes with only lang work
              // (doesn't help for default langauges with no slug, which is correct!)
              path: this._getLangSlug(false),
              name: 'lang',
              redirect: this._getHomeUri()
            }, {
              path: this._getLangSlug(true) + this._getHomeSlug(),
              name: 'home'
            }, {
              path: this._getLangSlug(true) + '/:slug(.+)',
              name: 'default'
              // Rewirte route path for api request
              // meta: {
              //   api: '/bar' (without lang)
              // }
            }
          ]
        }
      ]
    })

    this.Instance.beforeEach((to, from, next) => {
      if (Store.getters.siteError) {
        next()
        return
      }

      // normalize navigation options
      // we use params.slug, because we need url without language
      var nav = {
        to: {
          path: fn.has(to.meta, 'api') ? to.meta.api : to.params.slug,
          lang: this._detectLanguageFromRoute(to),
          hash: fn.ltrim(to.hash, '#'),
          name: to.name
        },
        from: {
          path: fn.has(from.meta, 'api') ? from.meta.api : from.params.slug,
          lang: this._detectLanguageFromRoute(from),
          hash: fn.ltrim(from.hash, '#'),
          name: from.name
        }
      }

      // no request if route or query didn't change (hash not observed!)
      if (
        nav.to.path === nav.from.path &&
        nav.to.lang === nav.from.lang &&
        fn.isEqual(to.query, from.query)) {
          if (nav.to.hash !== nav.from.hash && !to.params.isUserScrolled) {
            this._scroll(nav, true)
          }
          next()
          return
      }

      Promise.resolve()
        .then(() => {
          return Store.dispatch('loadLanguage', nav.to.lang)
        })
        .then(() => {
          info('change api route ' + nav.to.path)
          return API.node(nav.to.path, to.query)
        })
        .then((response) => {
          response.pagekey = nav.to.path
          Store.commit('setPageData', response)
          this._scroll(nav, false)
          next()
          Analytics.track(to.fullPath, from.fullPath)
        })
        .catch((response) => {
          if (!fn.has(response, 'status')) {
            response.status = 1000
          }
          response.pagekey = nav.to.path
          Store.commit('setPageData', response)
          this._scroll(nav, false)
          next()
          Analytics.track(to.fullPath, from.fullPath) // track error page!
        })
    })

    this.Instance.home = () => {
      return this._getHomeUri()
    }

    this.Instance.getTemplate = (blueprint) => {
      return this._getTemplate(blueprint)
    }

    this.Instance.getPage = (blueprint) => {
      return this._getPage(blueprint)
    }

    this.Instance.getBlueprint = (blueprint) => {
      return this._getBlueprint(blueprint)
    }

    return this.Instance
  }

  /*
  |--------------------------------------------------------------------------
  | Helper
  |--------------------------------------------------------------------------
  */

  _scroll (nav, samePage) {
    if (nav.to.hash) {
      if (samePage) {
        Scroll.to(nav.to.hash).catch(() => {})
      } else {
        Scroll.register('router/scroll-mounted', nav.to.hash)
      }
    } else {
      Scroll.to(0).catch(() => {})
    }
  }
 
  /**
   * Load a template
   * @param {String} blueprint, optional
   * @return {Component}
   */
  _getTemplate (blueprint) {
    blueprint = this._getBlueprint(blueprint)
    return templates[blueprint] || templates.default
  }

  /**
   * Load a page
   * @param {String} blueprint, optional
   * @return {Component}
   */
  _getPage (blueprint) {
    blueprint = this._getBlueprint(blueprint)
    return pages[blueprint] ||  pages.default
  }

  /**
   * Helper
   * @param {String} blueprint, optional
   * @return {String}
   */
  _getBlueprint (blueprint) {
    if (Store.getters.pageError) {
      blueprint = 'error'
    } else if (!fn.isString(blueprint)) {
      blueprint = Store.state.page.head.blueprint
    }
    return fn.lower(blueprint)
  }

  /**
   * 
   * @param {bool} optional, add optional quantifier (?), if a language
   * without a slug exists
   */
  _getLangSlug (optional) {
    let reg = []
    let quantifier = ''
    fn.each(Store.state.languages, (language) => {
      if (language.slug) {
        reg.push(language.slug)
      } else if (optional) {
        quantifier = '?'
      }
    })
    if (reg.length > 0) {
      return '/:lang(' + reg.join('|') + ')' + quantifier
    } else {
      return ''
    }
  }

  _getHomeSlug () {
    if (Store.getters.siteError) {
      return '/:slug(error)'
    }
    let reg = []
    fn.each(Store.state.languages, (language) => {
      reg.push(language.homeslug)
    })
    reg = fn.unique(reg)
    return '/:slug(' + reg.join('|') + ')'
  }

  _getHomeUri () {
    return fn.toUri(
      Store.getters.currentLanguage('slug'),
      Store.getters.currentLanguage('homeslug')
    )
  }

  _detectLanguageFromRoute (route) {
    if (!Store.getters.isMultiLanguage) {
      return Store.state.lang
    }
    if (fn.has(route.params, 'lang') && fn.isString(route.params.lang)) {
      return route.params.lang
    }

    // in multilang sites and no lang-param in url, the language
    // is the one without slug
    var res = null
    fn.each(Store.state.languages, (language, key) => {
      if (!language.slug) {
        res = key
      }
    })
    return res
  }
}

export default new routerService()
