import Store from '@/services/Store'

const AnalyticsPlugin = class {

  /*
  |--------------------------------------------------------------------------
  | Register
  |--------------------------------------------------------------------------
  */

  install (Vue, options) {
    this.Tracker = null
    this.options = fn.assign({
      tracker: null,
      url: null,
      id: null
    }, options)

    Vue.prototype.$analytics = {
      init: (path) => {
        return this.init(path)
      },
      track: (path, referrer) => {
        return this.track(path, referrer)
      },
      active: () => {
        return this.active()
      }
    }

    if (this.active()) {

      // init, because consent was already given at last visit
      // otherwise Cookie.vue.ok() will init analytics
      if (Store.getters['cookie/get']('analytics')) {
        this.init()
      }

      // setting cookie value will show cookie dialog
      else {
        Store.dispatch('cookie/set', { analytics: false })
      }
    }
  }
  
  /*
  |--------------------------------------------------------------------------
  | Interface
  |--------------------------------------------------------------------------
  */

  /**
   * allow tracking and optionally track current route
   * @param {string} path, optional
   */
  init () {
    if (this.Tracker || !this.active()) {
      return
    }

    // load tracker
    var Module
    switch (this.options.tracker) {
      case 'matomo':
        Module = import(/* webpackChunkName: `matomo-tracker` */ '@/plugins/analytics/Matomo')
        break
      case 'googleAnalytics':
        Module = import(/* webpackChunkName: `ga-tracker` */ '@/plugins/analytics/GoogleAnalytics')
        break
    }
    return Module.then((Tracker) => {
      this.Tracker = new Tracker.default()
      this.Tracker.init(this.options.url, this.options.id)
      Store.dispatch('cookie/set', { analytics: true })
    })
    .catch(() => {})
  }

  track (path, referrer) {
    if (this.Tracker) {
      this.Tracker.track(path, referrer)
    }
  }

  active () {
    return process.env.NODE_ENV !== 'development' &&
      fn.isString(this.options.tracker) &&
      fn.isString(this.options.url) &&
      (fn.isString(this.options.id) || fn.isInteger(this.options.id))
  }
}

export default new AnalyticsPlugin()